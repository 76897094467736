import { template as template_b6e843f57a174c86b3de7a6b32949e93 } from "@ember/template-compiler";
const FKLabel = template_b6e843f57a174c86b3de7a6b32949e93(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
