import { template as template_277f9fd9e40645cc87f2b0103310e7cc } from "@ember/template-compiler";
const FKText = template_277f9fd9e40645cc87f2b0103310e7cc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
