import { template as template_ccc22f9a9c414535828953fd48e70ee6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ccc22f9a9c414535828953fd48e70ee6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
