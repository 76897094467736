import { template as template_adc284dc41cb4e7ba75b54759ad6770f } from "@ember/template-compiler";
const FKControlMenuContainer = template_adc284dc41cb4e7ba75b54759ad6770f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
