import { template as template_419168804986479fa62ac5a9d05ba8d6 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import DToggleSwitch from "discourse/components/d-toggle-switch";
export default class FKControlToggle extends Component {
    static controlType = "toggle";
    @action
    handleInput() {
        this.args.field.set(!this.args.field.value);
    }
    static{
        template_419168804986479fa62ac5a9d05ba8d6(`
    <DToggleSwitch
      @state={{@field.value}}
      disabled={{@field.disabled}}
      {{on "click" this.handleInput}}
      class="form-kit__control-toggle"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
